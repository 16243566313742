<template>
    <div>
      <the-app-bar />
      <FoodhubPurchaseDetail/>
      <Footer />
    </div>
  </template>
  
  <script>
  import Footer from "../../../components/footer.vue";
  import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
import FoodhubPurchaseDetail from "../../../components/PurchasedPack/foodhubPurchaseDetail.vue";
  
  export default {
    components: { TheAppBar, Footer, FoodhubPurchaseDetail }
  };
  </script>
  
  <style lang="scss" scoped></style>
  